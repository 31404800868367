import { Backdrop, Box, CardContent, CircularProgress, Fade, Modal } from "@mui/material";
import { modalStyle } from "../../config/constant";
import React, { useEffect, useState } from "react";
import axios from "axios";


const Image = ({ data, open, setOpen }) => {

  const handleClose = () => setOpen(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const qy = document.querySelector.bind(document);

  const [imageData, setImageData] = useState(null);

  
  const getIMage = async () => {
    setLoading(true); setError(false);
    await axios(`${process.env.REACT_APP_API_URL}/api/documents/b_v1/user/file/${data.diploma}`, {
      headers: {Authorization: sessionStorage.getItem("token")}, responseType: "blob",
    }).then((blob) => {
        setLoading(false);
        setTimeout(() => { console.log(qy("#image"))
            qy("#image").src = window.URL.createObjectURL(blob.data);
        });
    })
      .catch((err) => setLoading(false));
  };

  async function fetchImageData() {
    try {
      const response = await axios(`${process.env.REACT_APP_API_URL}/api/documents/b_v1/user/file/${data.diploma}`, {
        headers: {Authorization: sessionStorage.getItem("token")}, responseType: "blob",
      });
      const blob = await response.data.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error fetching image data:', error);
    }
  }
  
  useEffect(() => {
    // fetchImageData();
    getIMage()
  }, [])

  return (
    <Modal aria-labelledby="img-modal" aria-describedby="img-modal-desc" open={open} onClose={handleClose} 
      closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={{ backdrop: { timeout: 500 } }}>
      <Fade in={open}>
        <Box sx={modalStyle}>
          <div className="d-flex justify-content-center flex-column">
            <div className="card">
              <CardContent style={{ padding: "5px 1%" }}>
                {loading ? (
                  <div className="spinner-border text-info my-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                  </div>
                ) : <iframe id="image" src="" alt="" height="520" width="900" />}
              </CardContent>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Image;
