import React, { useEffect, useState } from "react";
import UserList from "./UsersList";
import { Link } from "react-router-dom";
import axios from "axios";

const Dashboard = () => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [news, setNews] = useState(null);

  const getData = async () => {
    setLoading(true);
    await axios(`${process.env.REACT_APP_API_URL}/api/documents/b_v1/user`, {
      headers: { Authorization: sessionStorage.getItem("token") },
    })
      .then((res) => { setData(res?.data?.data); setLoading(false) })
      .catch((err) => { setLoading(false); console.log(err); });
  };

  const getNews = async () => {
    await axios(`${process.env.REACT_APP_API_URL}/api/documents/b_v1/news`, {
      headers: { Authorization: sessionStorage.getItem("token") },
    })
      .then((res) => setNews(res?.data?.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNews()
    getData()
  }, [])

  return (
    <main className="position-relative">
      { loading ? <div className="css-overlay"></div> : null }
      <div className="head-title">
        <div className="left">
          <h1>Dashboard</h1>
          <ul className="breadcrumb">
            <li><Link to="/">Dashboard</Link></li>
            <li><i className="bx bx-chevron-right"></i></li>
            <li><a className="active" href="#/">Home</a></li>
          </ul>
        </div>
      </div>

      <ul className="box-info">
        <li>
          <i className="bx bxs-group"></i>
          <span className="text">
            <h3>{data?.length}</h3>
            <p>Users</p>
          </span>
        </li>
        <li>
          <i className="bx bxs-calendar-check"></i>
          <span className="text">
            <h3>{news?.length}</h3>
            <p>News</p>
          </span>
        </li>
        <li>
          <i className="bx bxs-dollar-circle"></i>
          <span className="text">
            <h3>0</h3>
            <p>reservations</p>
          </span>
        </li>
      </ul>

      <UserList data={data} getData={getData}/>

    </main>
  );
};

export default Dashboard;
