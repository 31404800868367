import NewsList from "./NewsList";
import CreateItems from "./CreateNews";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

const Index = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    setLoading(true);
    await axios(`${process.env.REACT_APP_API_URL}/api/documents/b_v1/news`, {
      headers: { Authorization: sessionStorage.getItem("token") },
    })
      .then((res) => { setData(res?.data?.data); setLoading(false) })
      .catch((err) => { setLoading(false); console.log(err) });
  };

  useEffect(() => {
    getData()
  }, [])

  return (
    <main className="position-relative">
      { loading ? <div className="css-overlay"></div> : null }
      <div className="head-title">
        <div className="left">
          <h1>News</h1>
          <ul className="breadcrumb">
            <li><Link to="/">Dashboard</Link></li>
            <li><i className="bx bx-chevron-right"></i></li>
            <li><a className="active" href="#/">News</a></li>
          </ul>
        </div>
      </div>

      <CreateItems getData={getData} />
      <NewsList data={data} loading={loading} getData={getData} />
    </main>
  );
};

export default Index;
