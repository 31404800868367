import LoginPage from "../components/Login/LoginPage";

const Login = () => {
  return (
    <div className="all_center">
      <LoginPage />
    </div>
  );
};

export default Login;
