import { Button, CardContent, CircularProgress, Grid } from "@mui/material";
import { emptyTicketValue, modalStyle, ticketSchema } from "../../config/constant";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useState } from "react";
import axios from "axios";


export default function TransitionsModal({getData}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const qy = document.querySelector.bind(document);

  const submit = async (value) => {
    const img = (qy('#file').files[0])
    const body = new FormData()
    body.append('title', value.title)
    body.append('image', img)
    body.append('content', value.content)
  
    axios.post(`${process.env.REACT_APP_API_URL}/api/documents/b_v1/news`, body, {
      headers: { Authorization: sessionStorage.getItem("token") },
    })
      .then((res) => { setLoading(false); handleClose(); getData(); toast.success("Muvaffaqiyatli qo'shildi!") })
      .catch((err) => {setLoading(false); console.log(err); toast.error("Xatolik yuz berdi!")});
  };

  return (
    <div>
      <Button className="float-end mt-2" variant="contained" onClick={handleOpen}>Create News</Button>
      <Modal aria-labelledby="news-modal" aria-describedby="news-modal-desc" open={open} onClose={handleClose} 
        closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={{ backdrop: { timeout: 500 } }}>
        <Fade in={open}>
          <Box sx={modalStyle}>
            <div className="d-flex justify-content-center flex-column">
              <div className="card">
                <h4 className="text_title">Create News</h4>
                <CardContent style={{ padding: "15px 5%" }}>
                  <Formik initialValues={emptyTicketValue} validationSchema={ticketSchema} onSubmit={(values) => { submit(values) }}>
                    <Form autoComplete="off">
                      <Grid container direction="column" spacing={2}>
                        <Grid item container spacing={2} xs={12}>
                          <Grid item xs={12} sm={6}>
                            <Field fullWidth name="title" component={TextField} disabled={loading} label="Title" size="small"/>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field id="file" fullWidth type="file" name="image" component={TextField} disabled={loading} size="small"/>
                          </Grid>
                          <Grid item xs={12}>
                            <Field fullWidth name="content" component={TextField} disabled={loading} label="Content" size="small"/>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <Button className="float-end mt-2" disabled={loading} type="submit" variant="contained"
                              startIcon={ loading ? <CircularProgress size="0.9rem" /> : undefined }
                            >
                              {loading ? "Saving" : "Save"}
                            </Button>
                            <Button className="float-end mt-2 me-2" onClick={handleClose} disabled={loading} type="submit" variant="outlined">
                              cenel
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  </Formik>
                </CardContent>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}