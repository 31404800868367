import { Empty } from 'antd'
import React from 'react'
import ItemList from './itemList';
import { CircularProgress } from '@mui/material';

const NewsList = ({data, loading, getData}) => {

  return (
    <main className="section section-job-list gradient-light--lean-left">
      {loading && <div className="text-center w-100"><CircularProgress /></div>}
      {!loading && !data?.length && <div className="text-center"><Empty /></div>}
      <div id='card-list'>
        { data?.map(el => (<ItemList key={el?.id} id={el?.id} title={el?.title} image={el?.image} content={el?.content} getData={getData}/>)) }
      </div>
    </main>
  )
}

export default NewsList;