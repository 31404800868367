import axios from 'axios';
import * as React from 'react';
import Card from '@mui/material/Card';
import { toast } from 'react-toastify';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Button, CardActionArea, CardActions } from '@mui/material';

export default function ItemList({ id, title, image, content, getData }) {

  const handleDelete = (id) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/api/documents/b_v1/news/${id}`, {
      headers: { Authorization: sessionStorage.getItem("token") },
    })
      .then((res) => { toast.success("Muvaffaqiyatli o'chirildi"); getData() })
      .catch((err) => toast.error("O'chirishda xatolik yuz berdi!"));
  };

  return (
    <Card sx={{ maxWidth: 345 }} id='card'>
      <CardActionArea>
        <CardMedia component="img" height="140" image={image?.slice(0,4)==='data'?image:`data:image/jpeg;base64,${image}`} alt="green iguana"/>
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">{title?.length > 23 ? title?.slice(0,23)+'...' : title}</Typography>
          <Typography variant="body2" color="text.secondary">{content?.length > 50 ? content?.slice(0,50)+'...' : content}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{padding: "0px"}}>
        <Button onClick={()=>handleDelete(id)} size="small" variant="contained" color="error">delete</Button>
      </CardActions>
    </Card>
  );
}