import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
// import { visuallyHidden } from "@mui/utils";
import Alert from "@mui/material/Alert";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import UpdateUser from "./UpdateUser";
import { Empty } from "antd";
import { toast } from "react-toastify";
import axios from "axios";
import Image from "./image";
import Passport from "./image2";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) { return order; }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}


function EnhancedTableHead(props) {

  const headCells = [
    { id: "id", numeric: true, label: "ID" },
    { id: "first_name", numeric: true, label: "Ism" },
    { id: "last_name", numeric: true, label: "Familiya" },
    { id: "birth_date", numeric: true, label: "Tug'ulgan s" },
    { id: "document_number", numeric: true, label: "Seriya raqam" },
    { id: "speciality_of_education", numeric: true, label: "Yo'nalishi" },
    { id: "actions", numeric: true, label: "Action" },
  ];
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel style={{fontWeight: '550'}}
              active={orderBy === headCell.id}
              onClick={createSortHandler(headCell.id)} 
              direction={orderBy === headCell.id ? order : "asc"}
            >{headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function UsersList({ error, data, getData }) {

  const [id, setId] = React.useState(null);
  const [idx, setIdx] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchor, setAnchor] = React.useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openMenu = (event, elem) => {
    setIdx(elem);
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => setAnchor(null);

  const handleUpdate = () => {
    setOpen(true)
    setAnchor(null)
  };

  const handleDelete = async () => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/documents/b_v1/user/${idx?.id}`, {
      headers: { Authorization: sessionStorage.getItem("token")},
    })
    .then((res) => {
      getData(); setAnchor(null); setIdx(null)
      toast.success("Muvafaqqiyatli o'chirildi");
    })
    .catch((err) => {
      console.log(err);
      toast.error("O'chirishda xatolik yuz berdi!");
    });
  }

  return (
    <div className="container tab">
      { (open && idx) ? <UpdateUser data={idx} open={open} getData={getData} setOpen={setOpen}/> : null }
      { (open1 && idx) ? <Image data={idx} open={open1} setOpen={setOpen1}/> : null }
      { (open2 && idx) ? <Passport data={idx} open={open2} setOpen={setOpen2}/> : null }
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        {!error && data?.length === 0 && <div className="text-center"><Empty /></div>}
        {error && <Alert variant="outlined" severity="error">An error occurred</Alert>}
          
        {
          data.length ? 
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={data?.length}/>
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy))
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          <TableCell align="left">{row?.id}</TableCell>
                          <TableCell align="left">{row?.first_name}</TableCell>
                          <TableCell align="left">{row?.last_name}</TableCell>
                          <TableCell align="left">{row?.birth_date?.slice(0, 10)}</TableCell>
                          <TableCell align="left">{row?.document_number}</TableCell>
                          <TableCell align="left">{row?.speciality_of_education}</TableCell>
                          <TableCell align="left">
                            <Box sx={{ flexGrow: 0 }}>
                              <Tooltip title="Actions">
                                <IconButton style={{ borderRadius: "50%", padding: "2px" }} 
                                  onClick={(e, elem = row) => openMenu(e, elem)} sx={{ p: 0 }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                onClick={() => setId(row.id)}
                                sx={{ mt: "23px" }} id="menu-appbar"
                                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                anchorEl={anchor} open={Boolean(anchor)} onClose={closeMenu}
                              >
                                <MenuItem onClick={handleUpdate}><Typography>Tahrirlash</Typography></MenuItem>
                                <MenuItem onClick={()=>setOpen1(true)}><Typography>Diploma</Typography></MenuItem>
                                <MenuItem onClick={()=>setOpen2(true)}><Typography>Passport</Typography></MenuItem>
                                <MenuItem onClick={handleDelete} ><Typography>O'chirish</Typography></MenuItem>
                              </Menu>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={data?.length ? data.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> : null
        }
      </Box>
    </div>
  );
}

export default React.memo(UsersList);
