import { Button, CardContent, CircularProgress, Grid, MenuItem } from "@mui/material";
import { modalStyle } from "../../config/constant";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";


const UpdateUser = ({ data, getData, open, setOpen }) => {

  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);

  const submit = async (value) => {
    delete value.diploma;
    delete value.document;
    delete value.created_date;
    setLoading(true);
    await axios.put(`${process.env.REACT_APP_API_URL}/api/documents/b_v1/user`, value, {
      headers: { Authorization: sessionStorage.getItem("token") },
    }).then((res) => { getData(); toast.success('Yangilandi!'); setOpen(false)})
    .catch(err => { console.log(err); toast.error('Xatolik yuz berdi!')})
    .finally(() => setLoading(false))
  };

  return (
    <Modal aria-labelledby="user-modal" aria-describedby="user-modal-desc" open={open} onClose={handleClose} 
      closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={{ backdrop: { timeout: 500 } }}>
      <Fade in={open}>
        <Box sx={modalStyle}>
          <div className="d-flex justify-content-center flex-column">
            <div className="card">
              <h4 className="text_title">Update document</h4>
              <CardContent style={{ padding: "15px 5%" }}>
                <Formik initialValues={data} validationSchema={''} onSubmit={(values) => submit(values)}>
                  <Form autoComplete="off">
                    <Grid container direction="column" spacing={2}>
                      <Grid item container spacing={2} xs={12}>
                        <Grid item xs={12} sm={6}>
                          <Field fullWidth disabled={loading} variant="standard" name="first_name" component={TextField} label="First name" defaultValue={data?.first_name}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field fullWidth disabled={loading} variant="standard" name="last_name" component={TextField} label="Last name" defaultValue={data?.last_name}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field fullWidth disabled={loading} variant="standard" name="middle_name" component={TextField} label="Middle name" defaultValue={data?.middle_name}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field type='date' fullWidth disabled={loading} variant="standard" name="birth_date" component={TextField} label="Birth date" defaultValue={data?.birth_date?.slice(0,10)}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field fullWidth disabled={loading} select variant="standard" name="gender" 
                            component={TextField} label="Gender" defaultValue={data?.gender}
                          >
                            <MenuItem value="male">male</MenuItem>
                            <MenuItem value="female">female</MenuItem>
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field fullWidth disabled={loading} variant="standard" name="document_number" component={TextField} label="Document number" defaultValue={data?.document_number}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field fullWidth disabled={loading} variant="standard" name="phone" component={TextField} label="Phone" defaultValue={data?.phone}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field fullWidth disabled={loading} variant="standard" name="address" component={TextField} label="Address" defaultValue={data?.address}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field fullWidth disabled={loading} variant="standard" name="speciality_of_education" component={TextField} label="Speciality of education" defaultValue={data?.speciality_of_education}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field fullWidth disabled={loading} select variant="standard" name="education_type" 
                            component={TextField} label="Education type" defaultValue={data?.education_type}
                          >
                            <MenuItem value="kunduzgi">kunduzgi</MenuItem>
                            <MenuItem value="sirtqi">sirtqi</MenuItem>
                            <MenuItem value="kechki">kechki</MenuItem>
                          </Field>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Button
                            className="float-end mt-2"
                            disabled={loading}
                            type="submit"
                            variant="contained"
                            startIcon={ loading ? <CircularProgress size="0.9rem" /> : undefined }
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </Formik>
              </CardContent>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpdateUser;
