import { object, string } from "yup";

const allRegEx = /^[A-Za-z0-9'":;$@#&*!?,.-_+=(){}/ ]*$/;

export const emptyTicketValue = {
  title: "",
  image: "",
  content: "",
};

export const ticketSchema = object({
  title: string().matches(allRegEx, { message: "Invalid title" }).required("Title is required"),
  image: string().required("Image is required"),
  content: string().required("Content is required"),
})

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  width: 925,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  padding: 0,
  zIndex:1000
};