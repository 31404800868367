import { Route, Routes } from "react-router-dom";
import News from "../News/index.js";
import Dashboard from "./Dashboard";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";

const Admin = () => {
  return (
    <div>
      <Sidebar />
      <section id="content">
        <Navbar />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/news" element={<News />} />
        </Routes>
      </section>
    </div>
  );
};

export default Admin;
